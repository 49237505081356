@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}
.tech-stack {
  padding: 5px;
  padding-top: 1px;
  margin-top: 0;
  border: thin dashed #becade;
}
.tech-stack:nth-child(2){
  /*color: #000;*/
  border-top: none ;
  padding-top: 5px;

}
.tech-label {
  font-weight: 900;
  float: left;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


/*@import url(https://fonts.googleapis.com/css?family=Open+Sans);*/
.login .btn {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    padding: 4px 10px 4px;
    margin-bottom: 0;
    font-size: 13px;
    line-height: 18px;
    color: #333333;
    text-align: center;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
    vertical-align: middle;
    background-color: #f5f5f5;
    background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
    background-repeat: repeat-x;
    -webkit-filter: progid:dximagetransform.microsoft.gradient(startColorstr=#ffffff, endColorstr=#e6e6e6, GradientType=0);
            filter: progid:dximagetransform.microsoft.gradient(startColorstr=#ffffff, endColorstr=#e6e6e6, GradientType=0);
    border-color: #e6e6e6 #e6e6e6 #e6e6e6;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    *margin-left: .3em;
}

.login .btn:hover, .login .btn:active, .login .btn.active, .login .btn.disabled, .login .btn[disabled] {
    background-color: #e6e6e6;
}

.login .btn-large {
    padding: 9px 14px;
    font-size: 15px;
    line-height: normal;
    border-radius: 5px;
}

.login .btn:hover {
    color: #333333;
    text-decoration: none;
    background-color: #e6e6e6;
    background-position: 0 -15px;
    transition: background-position 0.1s linear;
}

.login .btn-primary, .login .btn-primary:hover {
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    color: #ffffff;
}

.login .btn-primary.active {
    color: rgba(255, 255, 255, 0.75);
}

.login .btn-primary {
    background-color: #4a77d4;
    background-image: linear-gradient(to bottom, #6eb6de, #4a77d4);
    background-repeat: repeat-x;
    -webkit-filter: progid:dximagetransform.microsoft.gradient(startColorstr=#6eb6de, endColorstr=#4a77d4, GradientType=0);
            filter: progid:dximagetransform.microsoft.gradient(startColorstr=#6eb6de, endColorstr=#4a77d4, GradientType=0);
    border: 1px solid #3762bc;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.5);
}

.login .btn-primary:hover, .login .btn-primary:active, .login .btn-primary.active, .login .btn-primary.disabled, .login .btn-primary[disabled] {
    -webkit-filter: none;
            filter: none;
    background-color: #4a77d4;
}

.login .google-btn {
    width: 95%;
    display: block;
    border-radius: 10px !important;
    padding: 0 10px !important;
}

/** { -webkit-box-sizing:border-box; -moz-box-sizing:border-box; -ms-box-sizing:border-box; -o-box-sizing:border-box; box-sizing:border-box; }*/

.login-body {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
}

.login-body {
    display: flex;
    /*position: fixed;*/
    /*width: 100%;*/
    /*height:100%;*/
    font-family: 'Open Sans', sans-serif;
    background: #092756;
    background: -webkit-radial-gradient(0% 100%, ellipse cover, rgba(104, 128, 138, .4) 10%, rgba(138, 114, 76, 0) 40%), linear-gradient(to bottom, rgba(57, 173, 219, .25) 0%, rgba(42, 60, 87, .4) 100%), linear-gradient(135deg, #670d10 0%, #092756 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3E1D6D', endColorstr='#092756', GradientType=1);
}

.login {
    position: absolute;
    top: 50%;
    background: darkslategrey;
    left: 50%;
    margin: -150px 0 0 -150px;
    width: 350px;
    height: 400px;
    padding: 15px;
}

.login h1, .login h2 {
    color: #fff;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    letter-spacing: 1px;
    text-align: center;
}

.login h2 {
    color: #fff;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    font-size: large;
    text-align: center;
}

.login h6, .login h5 {
    color: #fff;
    padding: 0;
    margin: 0;
    text-align: center;
}

.login h5 {
    color: #becade;
    padding: 0;
    margin-top: 20px;
    text-align: center;
}

.login a {
    color: #dee;
    padding: 0;
    /*margin-top: 2px;*/
    text-align: center;
}

.login input {
    width: 90%;
    margin-bottom: 10px;
    background: rgba(0, 0, 0, 0.3);
    border: none;
    outline: none;
    padding: 10px;
    font-size: 13px;
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    box-shadow: inset 0 -5px 45px rgba(100, 100, 100, 0.2), 0 1px 1px rgba(255, 255, 255, 0.2);
    transition: box-shadow .5s ease;
}

.login input:focus {
    box-shadow: inset 0 -5px 45px rgba(100, 100, 100, 0.4), 0 1px 1px rgba(255, 255, 255, 0.2);
}

